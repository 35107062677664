<template>
    <div class="zt-page-content" ref="load">
        <div class="flex flex-v" style="height:100%;overflow:hidden;">
            <div class="list flex-1" style="overflow-y:auto;">
                <div v-for="(item,idx) in items" :key="idx" class="zt-block list-item">
                    <div class="list-item-top flex">
                        <div @click="goto('/factory/qygl/detail/'+item.trans_no)" style="width:220px;height:220px;margin-right:40px;">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                                :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                        </div>
                        <div class="lit-middle flex-1">
                            <!-- <div v-if="item.set" class="serie-name">{{item.set.name}}</div> -->
                            <h3 v-if="item.name" @click="goto('/factory/qygl/detail/'+item.trans_no)" class="item-name ellipsis2">{{item.name}}</h3>
                            <div class="item-series flex">
                                <div v-if="item.style">{{item.style.name}}</div>
                                <div v-if="item.cat">{{item.cat.name}}</div>
                            </div>
                            <div style="margin-top:77px;" class="price">￥{{item.price | priceFilt}}</div>
                        </div>
                    </div>
                    <div class="list-item-bottom">
                        <div v-if="item.trans_stat<0">
                            <div style="text-align:center;font-size:32px;padding:10px 0;">暂无工厂签约</div>
                        </div>
                        <div v-if="item.trans_stat>=0">
                            <div style="margin-bottom:17px" class="item-avatar flex flex-align-center">
                                <div style="width:20px;height:20px;border-radius:50%;overflow:hidden;margin-right:8px;">
                                    <div v-if="item.design_comp.logo" class="bg_img"
                                        :style="{backgroundImage: 'url(' + imgUrl+item.design_comp.logo + ')'}"></div>
                                </div>
                                <div v-if="item.design_comp">{{item.design_comp.name}}</div>
                            </div>
                            
                            <SignPlan v-if="item && item.id" :item="item" :planType="'bq_order'" @refresh="refreshItems"></SignPlan>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-if="pageShow" class="pagination-wrap">
                <el-pagination background layout="prev, pager, next" @current-change="currentChange"
                :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize"
                :total="total"></el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { facPriceList } from '@/service/factory';
    import { goSign,contractAddr,contractView } from '@/service/contract';
    import { getPayId } from '@/service/pay';
    import SignPlan from '@/components/tradeOrder/Buyer.vue';
    export default {
        components: {
            SignPlan,
        },
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                popTitle:null,
                popVisible:false,
                queryOpt: {
                    fname:'',
                    pagesize: 10,
                    page: 1,
                },
                total:0,
                searchValue:"",
                pageShow:false, // 强制刷新分页组件
                items:[],
                hackReset:true,//刷新子组件
            }
        },
        watch: {
            // queryOpt:{
            //     handler(){
            //         this.debounceRefreshItems();
            //     },
            //     deep: true
            // }
        },
        async created() {
            this.refreshItems();
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            if (this.$route.query.page && this.$route.query.page>1) {
                if (this.$route.query.fname) {
                    this.queryOpt.fname = this.$route.query.fname;
                    this.searchValue = this.queryOpt.fname;
                }
                this.queryOpt.page=parseInt(this.$route.query.page);
                this.refreshItems();
            } else {
                if (this.$route.query.fname) {
                    this.queryOpt.fname = this.$route.query.fname;
                    this.searchValue = this.queryOpt.fname;
                }
                this.refreshItems();
            }
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        methods: {
            goto(path){
                this.$router.push(path);
            },
            currentChange(e) {
                if (this.queryOpt.fname) {
                    this.searchValue = this.queryOpt.fname;
                    this.$router.push({
                        query:{ page:e, fname: this.queryOpt.fname}
                    });
                } else {
                    this.$router.push({
                        query:{ page:e }
                    });
                }
                this.refreshItems();
            },
            refreshItems(){
                this.items = [];
                facPriceList(this.queryOpt).then(rst=>{
                    console.log('facPriceList');
                    this.total=rst.total;
                    this.items = rst.list;
                    console.log(this.items);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
                console.log(this.items);
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .pagination-wrap {
        padding:12px 55px;
        text-align:center;
    }
    .list-item {
        box-shadow:0px 2px 4px rgba(0,0,0,0.16);
        font-size: 14px;
        color: #808080;
        transition: all 0.2s;
    }
    .list-item:hover {
      box-shadow: 0px 6px 16px rgba(0,0,0,0.16);
    }
    .list-item .list-item-top {
        padding: 20px 30px;
        border-bottom:1px solid rgba(204,204,204,1);
    }
    .list-item .list-item-bottom {
        padding: 20px 30px;
    }
    .lit-middle {
        min-width: 320px;
        padding-top: 19px;
    }
    .serie-name {
        display: inline-block;
        padding: 0 31px;
        height:21px;
        line-height: 21px;
        background:linear-gradient(90deg,rgba(251,199,190,1) 0%,rgba(254,165,166,1) 100%);
        border-radius:4px;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 5px;
        margin-bottom: 8px;
    }
    .item-name {
        font-size: 18px;
        color: #292929;
        height: 48px;
        line-height: 24px;
        margin-bottom: 8px;
        font-weight: bold;
    }
    .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 20px;
    }
    .item-series>div {
        padding: 0 24px;
        border-right: 1px solid #CCCCCC;
    }
    .item-series>div:first-child {
        padding-left: 0;
    }
    .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .price {
       font-size: 36px;
       color: #EE1818;
       line-height: 50px;
    }
    .item-plan {
        margin: 9px 0 0 25px;
    }
    .item-plan>>> .el-step__icon.is-icon,.el-step__icon {
        width: unset;
    }
</style>
<style scoped>
    /*----选项卡----*/
    .tab_menu {
        background: #fff;
        padding: 0 20px;
        margin: 20px;
    }
    .tab_menu .menu_item{
        height: 70px;
        line-height: 70px;
        margin-right:70px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
    /*--------查询按钮-------*/
    .screen-button {
        width: 48px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: #CC995A;
        border-radius: 6px;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 20px;
        cursor: pointer;
    }

    .editor>div:nth-child(-n+2) {
        margin-bottom: 20px;
    }
    div>>> .el-dialog {
        width: 30%;
        margin-top: 40vh !important;
    }

</style>